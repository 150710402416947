@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&family=Open+Sans:wght@600&display=swap');

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.must-container {
  margin-bottom: 1em;
  border: 0.1em solid #073b4c;
}

.must-item {
  position: relative;

  width: 15em;
  height: 1em;

  padding: 0.2em 0;

  border-bottom: 0.1em solid #073b4c;
  background-color: #daf3fc;
}

.must-text {
  position: absolute;
  z-index: 0;
  top: 0.6em;
  left: 1em;

  width: 100%;

  padding: 0;
  margin: 0;
  line-height: 0;
}

.must-line {
  position: absolute;
  z-index: 1;
  top: 0.5em;

  width: 0;
  height: 0.2em;

  background-color: #ef476f;

  transition: 0.5s;
}

.cross-out {
  width: 100%;
}

label.amplify-label {
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 14px;
  margin-top: 31px;
}

.amplify-button[data-fullwidth="true"] {
  background-color: #1a4af0;
  color: white;
  border: none;
  height: 48px;
  margin-bottom: 28px;
  margin-top: 32px;
}
.amplify-button[data-fullwidth="true"]:hover,
.amplify-button[data-fullwidth="true"]:active,
.amplify-button[data-fullwidth="true"]:focus {
  background-color: #486ef3;
  color: white;
}

.amplify-countrycodeselect .amplify-select {
  padding-inline-end: 0;
  padding-left: 40px;
}

.amplify-countrycodeselect .amplify-select__icon-wrapper {
  left: 10px;
}

.otp-wrapper {
  max-width: 310px;
  text-align: center;
  background: white;
  display: flex; }
  .otp-wrapper > div {
    width: 310px;
    justify-content: space-between; }
  .otp-wrapper .otp-code__input {
    border-radius: 0.5rem;
    font-family: "Inter", sans-serif;
    height: 40px;
    width: 40px !important;
    border: 1px solid #ccc;
    font-size: 14px;
    text-align: center;
    transition: all 0.2s ease;
    color: #020219; }
    .otp-wrapper .otp-code__input .dark {
      border-color: #020219; }
    .otp-wrapper .otp-code__input:focus {
      border-color: #020219;
      outline: 0; }
    .otp-wrapper .otp-code__input.otp-code__input--error {
      border-color: #e61f26; }
      .otp-wrapper .otp-code__input.otp-code__input--error:focus {
        box-shadow: 0 0 0 0.2rem rgba(230, 31, 38, 0.25); }
